<template>
  <div class="page-gray">
    <!-- 待支付才有的  START -->
    <div class="notice"     v-if="orderInfo.paymentStatus === 0 && !orderInfo.active">
        温馨提示：待支付订单还有
      <van-count-down :time="showDate(orderInfo.createTime)" format="HH:mm:ss" @finish="()=>finish(orderId)" style="display: inline-block"/>
      自动取消，请家长尽快支付。
    </div>
    <!-- 地址信息 -->
    <div class="addressInfo">
      <div class="addressItem van-hairline--bottom" v-if="null == orderInfo.province">
        <div class="contentInfo" >
          <div class="top" >
            <div class="name">{{ orderInfo.name }}</div>
            <div class="phone">{{ orderInfo.phone }}</div>
          </div>
          <div class="address">
            {{ orderInfo.school + orderInfo.grade + orderInfo.classn }}
          </div>
        </div>
      </div>
      <div class="addressItem" v-if="null != orderInfo.province">
        <img
            class="location"
            src="./../../assets/images/order/submitOrder/address_home.png"
            alt=""
        />
        <div class="contentInfo">
          <div class="top">
            <div class="name">{{ orderInfo.consignee }}</div>
            <div class="phone">{{ orderInfo.phone }}</div>
          </div>
          <div class="address">
              {{ orderInfo.province+orderInfo.city+orderInfo.district+orderInfo.address }}
          </div>
        </div>
<!--        <img
            class="arrow"
            src="./../../assets/images/order/submitOrder/arrow.png"
            alt=""
        />-->
      </div>
    </div>
    <div class="orderCard"  v-if="undefined != orderInfo.cancelData && orderInfo.cancelData.length>0">
      <div class="state" style="color: #D53A26">已取消</div>
      <div class="goodslist" v-for="(orderItem,index) in orderInfo.cancelData" :key="index">
        <div class="goodsItem">
          <div class="goodsInfo">
            <van-image
                :src="orderItem.pic"
            />
            <div class="right">
              <div class="top">
                <div class="name van-multi-ellipsis--l2">
                  <span class="ys" v-show="orderItem.libckflag ===0">预售</span>
                  <span class="xh" v-show="orderItem.libckflag ===1">现货</span>
                  <div style="display: inline;">{{ orderItem.subProductName }}</div>
                </div>
                <div class="price">
                  <span>¥</span>
                  <span>{{ orderItem.price }}</span>
                </div>
              </div>
              <div class="tagList" style="display: inline-block">
                <div class="tag"  v-show="orderItem.productType ==0">配送到校</div>
                <div class="tag" v-show="orderItem.productType ==1">快递到家</div>
              </div>
              <div style="margin-left: 10px; display: inline-block;">
                <div v-show="orderItem.deliverGoodsTemp === '1'"
                     class="deliveryTime">下单{{orderItem.day}}天内发货</div>
                <div v-show="orderItem.deliverGoodsTemp === '2'"
                     class="deliveryTime">预计配送时间：{{ orderItem.deliverGoodsDate }}</div>
                <div v-show="orderItem.deliverGoodsTemp === '3'"
                     class="deliveryTime"
                     v-html="orderItem.deliverGoodsContent">
                </div>
              </div>

              <div class="sku">规格：{{ orderItem.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ orderItem.num }}</div>

            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="orderCard"  v-if="undefined != orderInfo.unpaidData && orderInfo.unpaidData.length>0">
      <div class="state" style="color: #D53A26">等待买家付款</div>
      <div class="goodslist" v-for="(orderItem,index) in orderInfo.unpaidData" :key="index">
        <div class="goodsItem">
          <div class="goodsInfo">
            <van-image
                :src="orderItem.pic"
            />
            <div class="right">
              <div class="top">
                <div class="name van-multi-ellipsis--l2">
                  <span class="ys" v-show="orderItem.libckflag ===0">预售</span>
                  <span class="xh" v-show="orderItem.libckflag ===1">现货</span>
                  <div style="display: inline;">{{ orderItem.subProductName }}</div>
                </div>
                <div class="price">
                  <span>¥</span>
                  <span>{{ orderItem.price }}</span>
                </div>
              </div>
              <div class="tagList" style="display: inline-block">
                <div class="tag"  v-show="orderItem.productType ==0">配送到校</div>
                <div class="tag" v-show="orderItem.productType ==1">快递到家</div>
              </div>
              <div style="margin-left: 10px; display: inline-block;">
                <div v-show="orderItem.deliverGoodsTemp === '1'"
                     class="deliveryTime">下单{{orderItem.day}}天内发货</div>
                <div v-show="orderItem.deliverGoodsTemp === '2'"
                     class="deliveryTime">预计配送时间：{{ orderItem.deliverGoodsDate }}</div>
                <div v-show="orderItem.deliverGoodsTemp === '3'"
                     class="deliveryTime"
                     v-html="orderItem.deliverGoodsContent">
                </div>
              </div>

              <div class="sku">规格：{{ orderItem.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ orderItem.num }}</div>

            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="orderCard" v-if="(undefined != orderInfo.inProductionData && orderInfo.inProductionData.length>0)
    || (undefined != orderInfo.beShippedData && orderInfo.beShippedData.length>0)">
      <div class="state" style="color: #D53A26" >备货中</div>
      <div class="goodslist" v-for="(orderItem,index) in orderInfo.inProductionData" :key="index">
        <div class="goodsItem">
          <div class="goodsInfo">
            <van-image
                :src="orderItem.pic"
            />
            <div class="right">
              <div class="top">
                <div class="name van-multi-ellipsis--l2">
                  <span class="ys" v-show="orderItem.libckflag ===0">预售</span>
                  <span class="xh" v-show="orderItem.libckflag ===1">现货</span>
                  <div style="display: inline;">{{ orderItem.subProductName }}</div>
                </div>
                <div class="price">
                  <span>¥</span>
                  <span>{{ orderItem.price }}</span>
                </div>
              </div>
              <div class="tagList" style="display: inline-block">
                <div class="tag"  v-show="orderItem.productType ==0">配送到校</div>
                <div class="tag" v-show="orderItem.productType ==1">快递到家</div>
              </div>
              <div style="margin-left: 10px; display: inline-block;">
                <div v-show="orderItem.deliverGoodsTemp === '1'"
                     class="deliveryTime">下单{{orderItem.day}}天内发货</div>
                <div v-show="orderItem.deliverGoodsTemp === '2'"
                     class="deliveryTime">预计配送时间：{{ orderItem.deliverGoodsDate }}</div>
                <div v-show="orderItem.deliverGoodsTemp === '3'"
                     class="deliveryTime"
                     v-html="orderItem.deliverGoodsContent">
                </div>
              </div>

              <div class="sku">规格：{{ orderItem.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ orderItem.num }}</div>

            </div>
          </div>
          <div class="btnList">
            <div class="plain active" @click.stop="changeSku([orderItem])">修改尺码</div>
          </div>
        </div>
      </div>
      <div class="goodslist" v-for="(orderItem,index) in orderInfo.beShippedData" :key="index">
        <div class="goodsItem" >
          <div class="goodsInfo">
            <van-image
                :src="orderItem.pic"
            />
            <div class="right">
              <div class="top">
                <div class="name van-multi-ellipsis--l2">
                  <span class="ys" v-show="orderItem.libckflag ===0">预售</span>
                  <span class="xh" v-show="orderItem.libckflag ===1">现货</span>
                  <div style="display: inline;">{{ orderItem.subProductName }}</div>
                </div>
                <div class="price">
                  <span>¥</span>
                  <span>{{ orderItem.price }}</span>
                </div>
              </div>
              <div class="tagList" style="display: inline-block">
                <div class="tag"  v-show="orderItem.productType ==0">配送到校</div>
                <div class="tag" v-show="orderItem.productType ==1">快递到家</div>
              </div>
              <div style="margin-left: 10px; display: inline-block;">
                <div v-show="orderItem.deliverGoodsTemp === '1'"
                     class="deliveryTime">下单{{orderItem.day}}天内发货</div>
                <div v-show="orderItem.deliverGoodsTemp === '2'"
                     class="deliveryTime">预计配送时间：{{ orderItem.deliverGoodsDate }}</div>
                <div v-show="orderItem.deliverGoodsTemp === '3'"
                     class="deliveryTime"
                     v-html="orderItem.deliverGoodsContent">
                </div>
              </div>

              <div class="sku">规格：{{ orderItem.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ orderItem.num }}</div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="orderCard" v-if="undefined != orderInfo.deliveryInProgressData && orderInfo.deliveryInProgressData.length>0">
      <div class="state" style="color: #D53A26">已发货</div>
      <div class="goodslist" v-for="(orderItem,index) in orderInfo.deliveryInProgressData"  :key="index">
        <div class="goodsItem" @click="$router.push('/order/logistics/'+orderItem.orderShipId)">
          <div class="goodsInfo">
            <van-image
                :src="orderItem.pic"
            />
            <div class="right">
              <div class="top">
                <div class="name van-multi-ellipsis--l2">
                  <span class="ys" v-show="orderItem.libckflag ===0">预售</span>
                  <span class="xh" v-show="orderItem.libckflag ===1">现货</span>
                  <div style="display: inline;">{{ orderItem.subProductName }}</div>
                </div>
                <div class="price">
                  <span>¥</span>
                  <span>{{ orderItem.price }}</span>
                </div>
              </div>
              <div class="tagList" style="display: inline-block">
                <div class="tag"  v-show="orderItem.productType ==0">配送到校</div>
                <div class="tag" v-show="orderItem.productType ==1">快递到家</div>
              </div>
              <div style="margin-left: 10px; display: inline-block;">
                <div v-show="orderItem.deliverGoodsTemp === '1'"
                     class="deliveryTime">下单{{orderItem.day}}天内发货</div>
                <div v-show="orderItem.deliverGoodsTemp === '2'"
                     class="deliveryTime">预计配送时间：{{ orderItem.deliverGoodsDate }}</div>
                <div v-show="orderItem.deliverGoodsTemp === '3'"
                     class="deliveryTime"
                     v-html="orderItem.deliverGoodsContent">
                </div>
              </div>

              <div class="sku">规格：{{ orderItem.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ orderItem.num }}</div>

            </div>
          </div>
          <div class="btnList">
            <div class="plain active" v-if="caclIsAfterSales(orderItem.wldate)" @click.stop="createAfterSales(orderItem)">申请售后</div>
          </div>
        </div>
      </div>
    </div>
    <div class="orderCard" v-if="undefined != orderInfo.signedForData && orderInfo.signedForData.length>0">
         <div class="state" style="color: #212121">已签收</div>
         <div class="goodslist" v-for="(orderItem,index) in orderInfo.signedForData" :key="index">
           <div class="goodsItem" @click="$router.push('/order/logistics/'+orderItem.orderShipId)">
             <div class="goodsInfo">
               <van-image
                   :src="orderItem.pic"
               />
               <div class="right">
                 <div class="top">
                   <div class="name van-multi-ellipsis--l2">
                     <span class="ys" v-show="orderItem.libckflag ===0">预售</span>
                     <span class="xh" v-show="orderItem.libckflag ===1">现货</span>
                     <div style="display: inline;">{{ orderItem.subProductName }}</div>
                   </div>
                   <div class="price">
                     <span>¥</span>
                     <span>{{ orderItem.price }}</span>
                   </div>
                 </div>
                 <div class="tagList" style="display: inline-block">
                   <div class="tag"  v-show="orderItem.productType ==0">配送到校</div>
                   <div class="tag" v-show="orderItem.productType ==1">快递到家</div>
                 </div>
                 <div style="margin-left: 10px; display: inline-block;">
                   <div v-show="orderItem.deliverGoodsTemp === '1'"
                        class="deliveryTime">下单{{orderItem.day}}天内发货</div>
                   <div v-show="orderItem.deliverGoodsTemp === '2'"
                        class="deliveryTime">预计配送时间：{{ orderItem.deliverGoodsDate }}</div>
                   <div v-show="orderItem.deliverGoodsTemp === '3'"
                        class="deliveryTime"
                        v-html="orderItem.deliverGoodsContent">
                   </div>
                 </div>

                 <div class="sku">规格：{{ orderItem.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ orderItem.num }}</div>

               </div>
             </div>

           </div>
         </div>
       </div>
    <div class="orderCard" v-if="undefined != orderInfo.returnOrderData && orderInfo.returnOrderData.length>0">
         <div class="state" style="color: #E7AC58">退款订单</div>
         <div class="goodslist" v-for="(orderItem,index) in orderInfo.returnOrderData" :key="index">
           <div class="goodsItem" v-for="(orderDetail,temp) in orderItem.orderDetailVos" :key="temp">
             <div class="goodsInfo" @click="$router.push(`/refund/detail/${orderItem.id}`)">
               <van-image
                   :src="orderDetail.pic"
               />
               <div class="right">
                 <div class="top">
                   <div class="name van-multi-ellipsis--l2">
                     <div style="display: inline;">{{ orderDetail.subProductName }}</div>
                   </div>
                   <div class="price">
                     <span>¥</span>
                     <span>{{ orderDetail.price }}</span>
                   </div>
                 </div>

                 <div class="sku">规格：{{ orderDetail.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ orderDetail.num }}</div>
                 <div style="margin-left: 30px; display: inline-block;">
                   <div class="deliveryTime">{{arr[orderItem.orderStatus]}}</div>
                 </div>
               </div>
             </div>
           </div>
           <div class="goodsItem" v-if="orderItem.postageProduct">
             <div class="goodsInfo" @click="$router.push(`/refund/detail/${orderItem.id}`)">
               <div class="right">
                 <div class="top">
                   <div class="name van-multi-ellipsis--l2">
                     <div style="display: inline;">邮费商品</div>
                   </div>
                   <div class="price">
                     <span>¥</span>
                     <span>{{ orderItem.postFee }}</span>
                   </div>
                 </div>

                 <div class="sku">规格：无&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：无</div>
                 <div style="margin-left: 30px; display: inline-block;">
                   <div class="deliveryTime">{{arr[orderItem.orderStatus]}}</div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
    <div class="orderCard" v-if="undefined != orderInfo.afterSalesData && orderInfo.afterSalesData.length>0">
       <div class="state" style="color: #D53A26">售后订单</div>
       <div class="goodslist" v-for="(orderItem,index) in orderInfo.afterSalesData" :key="index">
         <div class="goodsItem" @click="$router.push(`/afterSales/detail/${orderItem.id}`)">
           <div class="goodsInfo">
             <van-image
                 :src="orderItem.pic"
             />
             <div class="right">
               <div class="top">
                 <div class="name van-multi-ellipsis--l2">
                   <span class="ys" v-show="orderItem.libckflag ===0">预售</span>
                   <span class="xh" v-show="orderItem.libckflag ===1">现货</span>
                   <div style="display: inline;">{{ orderItem.subProductName }}</div>
                 </div>
                 <div class="price">
                   <span>¥</span>
                   <span>{{ orderItem.price }}</span>
                 </div>
               </div>
               <div class="tagList" style="display: inline-block">
                 <div class="tag"  v-show="orderItem.productType ==0">配送到校</div>
                 <div class="tag" v-show="orderItem.productType ==1">快递到家</div>
               </div>

               <div class="sku">规格：{{ orderItem.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ orderItem.num }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  售后类型：{{typeList[orderItem.type]}}</div>
               <div class="tips">售后状态：{{statusList[orderItem.status]}}</div>
             </div>
           </div>
         </div>
       </div>
     </div>
    <div class="orderInfoCard">
      <div class="top van-hairline--bottom">
        <div class="item">
          <div class="key">商品总价:</div>
          <div class="value">¥&nbsp;{{orderInfo.totalPrice}}</div>
        </div>
        <div class="item">
          <div class="key">邮费:</div>
          <div class="value">¥&nbsp;{{orderInfo.postFee}}</div>
        </div>
        <div class="item">
          <div class="key">实付款<span>（含邮费）</span>:</div>
          <div class="value price">¥&nbsp;{{orderInfo.payFee}}</div>
        </div>
      </div>

      <div class="top ">
        <div class="item">
          <div class="key">订单编号:</div>
          <div class="value gray">{{orderInfo.orderSn}}|<span @click="CopyFun(orderInfo.orderSn)">复制</span></div>
        </div>
        <div class="item">
          <div class="key">创建时间:</div>
          <div class="value gray">{{orderInfo.createTime}}</div>
        </div>
        <div class="item">
          <div class="key">付款时间:</div>
          <div class="value gray">{{orderInfo.payTime}}</div>
        </div>
      </div>
    </div>

    <div class="placeholder"></div>
    <div class="bottomHandle" >
      <van-button
          v-if="orderInfo.paymentStatus === 0 && !orderInfo.active"
          size="mini"
          type="info"
          plain round
          color="#212121"
          @click="cancelOrder()">取消订单</van-button>
      <van-button
          size="mini"
          type="primary"
          round color="#D61619"
          v-if="orderInfo.paymentStatus === 0
                && !orderInfo.active
                && dateTimeLimit(orderInfo)"
          @click="toPay()">立即支付</van-button>
      <van-button
                  v-if="orderInfo.paymentStatus===2
                  && orderInfo.returnOrderData.length==0
                  && orderInfo.fdflag+orderInfo.xsfd<1"
                  size="mini"
                  type="info"
                  plain round color="#212121"
                  @click="$router.push('/refund/create/'+orderInfo.id)">申请退款
      </van-button>
      <van-button
          v-if="orderInfo.fdflag+orderInfo.xsfd>0"
          size="mini"
          type="info"
          plain round
          color="#212121"
          @click="$router.push('/order/allLogistics/'+orderInfo.id)">查看物流</van-button>
    </div>
    <select-product-dialog ref="selectProductDialog" v-if="selectProductShow" @onNextStep="onNextStep"></select-product-dialog>
    <after-sales-dialog ref="createAfterSales" @onNextStep="onAfterSalesNextStep"/>
    <refund-dialog ref="refundDialog" @onNextStep="onRefundNextStep" />
    <confirm-refund-dialog ref="confirmRefundDialog"  @onNextStep="onRefundFinsh"/>
    <replace-product ref="sku" :key="timer"></replace-product>
    <global-web-socket uri="/mall/ws/info" @setload="setLoad"></global-web-socket>
  </div>
</template>

<script>
import GlobalWebSocket from '@/components/WebSocket/GlobalWebSocket'
import SKUTYPE from "@/views/goods/components/skuType";
import RefundDialog from './detailComponent/refundDialog.vue';
import AfterSalesDialog from './detailComponent/afterSalesDialog.vue';
import ConfirmRefundDialog from './detailComponent/confirmRefundDialog.vue';
import {mapState} from 'vuex'
import SelectProductDialog from "./detailComponent/selectProductDialog";
import ReplaceProduct from "../goods/change/replaceProduct";
import Vue from "vue";
import {transferDate} from "../../util/util";

export default {
  name: "OrderDetail",
  data() {
    return {
      dialog: false,
      orderId: "",
      pathUrl:"",//前一个页面路径
      //时间戳
      timer:'',
      orderInfo:{},
      selectProductShow:false,
      arr:["已申请","已确认","已完成","已取消","已驳回"],
      typeList: ["仅退款", '退货退款', '换货'],
      statusList: ['发起售后', '厂家审核', '商品寄回', '退款审核','退款成功','售后完成'],
    }
  },
  components: {
    ReplaceProduct,
    SelectProductDialog,
    AfterSalesDialog,
    RefundDialog,
    ConfirmRefundDialog,
    GlobalWebSocket,
  },
  //1 首先进入页面的时候需要监听浏览器的后退按钮，之后在操作的时候执行函数,
//在mounted中挂载去监听这个事件，并在methods里面添加函数，用来判断执行的条件
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.back, false)
    }

    this.orderId = this.$route.params.id;
    this.init();
  },
  // 2：需要在退出页面的时候销毁这个监听事件
  destroyed() {
    window.removeEventListener('popstate', this.back, false)
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.pathUrl = from.path;
    })
  },
  activated() {
    // this.init();
  },
  computed: {
    ...mapState(['studentInfo'])
  },
  methods: {
    back() {
      if(this.pathUrl.includes("/order/submit")){
        this.$router.go(-2)
      }else{
        this.$router.go(-1)
      }

    },
    //过期
    finish(orderId){
      this.init();
      // 每5000毫秒清除数据
      let intervalId = setInterval(()=> {
        //保存凭证
        this.$http.post(this.baseMallUrl + `/h5_mall_order/manuallyClearOrder/${orderId}`).then(response => {
          response
        })
        // 取消定时器
        clearInterval(intervalId);
      }, 3000);

    },
    //获取时间戳
    showDate(createTime){
      let date = transferDate(new Date(createTime.replace(/-/g, "/")));
      let min = date.getMinutes();
      let seconds = date.getSeconds();
      date.setMinutes(min+10);
      date.setSeconds(seconds+5);
      return  date.getTime() - Date.now();
    },
    init() {
      this.$http.get(`/mall/h5_mall_order/queryOrderDetail/${this.orderId}`).then(res => {
        this.orderInfo = res.data;
      });
    },
    //取消订单
    cancelOrder(){
      this.$dialog.confirm({
        title: '提示',
        message: `是否取消订单`,
      }).then(() => {
        this.$http.get(`/mall/h5_mall_order/cancelOrder/${this.orderId}`, {emulateJSON: true}).then(() => {
          this.$dialog.alert({
            title: '提示',
            message: '操作成功',
          })
          this.init();
        })
      }).catch(() => {
        // on cancel
      });

    },
    //去支付
    toPay(){
      this.$http.get(`/mall/h5_mall_order/orderDetailsToPay`).then(res => {
        res
        this.dialog=true
      });
    },
    setLoad(msgEvent){
      if(msgEvent.data !=='orderSuccess'){
        // this.$toast.fail(msgEvent.data);
        this.$dialog.alert({
          message: msgEvent.data,
        }).then(() => {
          // on close
        });
        let timer = setInterval(()=>{
          this.$toast.clear();
          clearInterval(timer);
        },2000)
        this.init();
        this.dialog = false;
        return ;
      }
      console.log(this.pullPayment)
      if(!this.pullPayment){
        //等待300毫秒
        let timeout = setTimeout(()=>{
          this.load = false;
          //获取后台数据
          this.$http.get(`/pay/goods/jsApiPay/${Vue.prototype.deptId}`,{
            params: {
              orderId: this.orderId,
            }
          }).then(data => {
            window.WeixinJSBridge.invoke(
                'getBrandWCPayRequest', data.data,
                (res)=>{
                  if(res.err_msg === "get_brand_wcpay_request:ok" ){
                    this.$toast.success('支付成功');
                    this.dialog = false;
                    let timer = setInterval(()=>{
                      this.$toast.clear();
                      clearInterval(timer);
                    },2000)
                    this.$router.replace('/order/paySuccess')
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  }else{
                    this.$toast("支付失败");
                  }

                });
          });
          clearTimeout(timeout);
        },300)
      }
      /*
      * 唤起支付
      * 1.提交后台拉取参数
      * 2.调取微信支付
      * */

    },
    //订单时间限制
    dateTimeLimit(orderInfo){
      if(!orderInfo.createTime){
        return false;
      }
      let createTime = orderInfo.createTime.replace(/-/g, '/');
      var newDate = new Date(createTime);
      //2. 获取当前分钟
      var min = newDate.getMinutes();
      //3. 设置当前时间+30分钟：把当前分钟数+30后的值重新设置为date对象的分钟数
      newDate.setMinutes(min + 30);

      var now = new Date();
      if(newDate>now){
        return true;
      }else{
        return false;
      }
    },
    changeSku(items) {
      this.timer = new Date().getTime();
      this.selectProductShow = true;
      if( items[0].num === 1){
        this.$nextTick(()=>{
          items[0].selectNum = items[0].num;
          this.$refs.sku.subProductId = items[0].subProductId;
          this.$refs.sku.changeNum = items[0].selectNum;
          this.$refs.sku.saleProductId = items[0].saleProduct.id;
          this.$refs.sku.libckflag = items[0].libckflag;
          this.$refs.sku.showPop(({data}) => {
            items[0].changeProductId = data.choiceProductId
            items[0].changeName = data.choiceProductName
            this.$http.post(this.baseMallUrl + `/h5_mall_order/modifyOrderMxSpecifications`, items[0], {emulateJSON: true}).then(() => {
              let timer = setInterval(()=>{
                this.init();
                this.selectProductShow = false;
                clearInterval(timer);
              },2000)
            })
          })
        })
      }else{
        this.$nextTick(()=>{
          this.$refs.selectProductDialog.showPop(items, items[0].subProductId,"changeSku");
        })
      }
    },
    remind() {
      this.$dialog.alert({
        title: '提示',
        message: '提醒成功'
      })
    },
    CopyFun(content) {
      let oInput = document.createElement("input");
      oInput.value = content;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$toast.success('复制成功');
      oInput.remove();
    },
    //选择商品回调
    onNextStep(goods, functionType){
      goods.forEach((item=>{
        item.orderId = this.orderInfo.id;
      }))
      let skuType = SKUTYPE.CUSTOM;
      if(functionType === "changeSku"){
        this.$refs.sku.subProductId = goods[0].subProductId;
        this.$refs.sku.changeNum = goods[0].selectNum;
        this.$refs.sku.saleProductId = goods[0].saleProduct.id;
        this.$refs.sku.libckflag = goods[0].libckflag;
        this.$refs.sku.showPop(({data}) => {
          goods[0].changeProductId = data.choiceProductId
          goods[0].changeName = data.choiceProductName
          this.$http.post(this.baseMallUrl + `/h5_mall_order/modifyOrderMxSpecifications`, goods[0], {emulateJSON: true}).then(() => {
            let timer = setInterval(()=>{
              this.init();
              this.selectProductShow = false;
              clearInterval(timer);
            },2000)
          })
        }, skuType)
      }

    },
    /**
     * 是否可以申请售后
     * @param dateTime
     */
    caclIsAfterSales(dateTime){
      if(!dateTime){
        return true;
      }
      // 获取当前日期
      var currentDate = new Date();
      // 设置发货日期为10天后（仅作示例）
      let deliveryDate = new Date(dateTime); // 将当前日期加上10天的毫秒数
      // 判断发货时间是否超过15天
      return (deliveryDate - currentDate) / (24 * 60 * 60 * 1000) <= 15;
    },
    /**
     * 申请售后
     */
    createAfterSales(orderItem) {
      this.$refs.createAfterSales.showPop([orderItem], orderItem.subProductId, 2);
    },
    /**
     * @param { Array } goodsList 理论上这个item应该是订单的商品合集, 比如[{goodsId: 1, goodsName: '夏季套装}, { goodsId: 2, goodsName: '夏季套装'}]
     * @param { String | Number } currentId 当前选中的 goodsId
     */
    createRefund(goodsList, currentId) {
      this.$refs.createAfterSales.showPop(goodsList, currentId, 1);
    },
    /**
     * @param { Array } goodsList 被选中的商品, 比如[{goodsId: 1, goodsName: '夏季套装}, { goodsId: 2, goodsName: '夏季套装'}]
     * @param { Number } type 售后类型  1仅退款， 2 退货退款 3 换货
     */
    onAfterSalesNextStep(goodsList, type) {
      if (type === 0 || type === 1) {
        // 如果是退款的 就打开退款弹窗
        this.$refs.refundDialog.showPop(goodsList,type)
      } else {
        localStorage.setItem('goodsList', JSON.stringify(goodsList));
        localStorage.setItem('type', type);
        // 否则跳转到换货的页面
        this.$router.push('/afterSales/create')
      }
    },
    onRefundNextStep(goodsList,type) {
      // this.$refs.confirmRefundDialog.showPop(goodsList,type)

      this.$http.post(this.baseMallUrl + `/h5_mall/userAppliesForAfterSalesService`,
          {orderItems:goodsList,
            afterSalesParam:{type:type,deptId:Vue.prototype.deptId}}, {
            emulateJSON: true
          }).then(response => {
        response
        this.$toast.success('申请成功')
        this.init()
      })
    },
    onRefundFinsh(goodsList,type,voucher,sendBackOrderNumber) {
      voucher = voucher || '';
      sendBackOrderNumber = sendBackOrderNumber || '';
      this.$http.post(this.baseMallUrl + `/h5_mall/userAppliesForAfterSalesService`,
          {orderItems:goodsList,
            afterSalesParam:{type:type,voucher:voucher.join(","),sendBackOrderNumber:sendBackOrderNumber,deptId:Vue.prototype.deptId}}, {
        emulateJSON: true
      }).then(response => {
        response
        this.$toast.success('申请成功')
        this.init();
      })
    },
  },
};
</script>
<style lang="less" scoped>
.ys {
  width: 32px;
  background: linear-gradient(264deg, #f58130, #ef2514);
  border-radius: 2px;

  font-size: 12px;
  font-weight: 500;
  color: #F4F4F4;
  line-height: 12px;
  text-align: center;
  padding: 2px 0;
  display: inline-block;
  margin-right: 3px;

}

.xh {
  width: 32px;
  background: linear-gradient(264deg, #4db3ee, #00A0E9);
  border-radius: 2px;

  font-size: 12px;
  font-weight: 500;
  color: #F4F4F4;
  line-height: 12px;
  text-align: center;
  padding: 2px 0;
  display: inline-block;
  margin-right: 3px;

}

.deliveryTime {
  margin: 0px 0 5px 0;
  font-size: 14px;
  color: #F3B66A;
}

.notice {
  height: 32px;
  line-height: 32px;
  padding: 0 17px;
  background-color: #fffbe8;
  font-size: 10px;
  color: #ff0000;
}

h1.state {
  font-size: 15px;
  font-weight: 500;
  color: #212121;
  line-height: 15px;
  padding: 14px 17px;
}

.addressInfo {
  padding: 0 18px;
  background-color: #fff;
  .addressItem {
    display: flex;
    align-items: center;
    &::after {
      border-color: #f6f6f6;
    }
    &:first-child {
      padding: 18px 0 15px 0;
    }

    &:last-child {
      padding: 15px 0 13px 0;
    }
    > img.location {
      width: 15px;
      min-width: 15px;
      margin-right: 9px;
    }

    > img.arrow {
      width: 8px;
      min-width: 8px;
      margin-left: 9px;
      margin-right: 12px;
    }
    .contentInfo {
      flex: 1;
      .top {
        .name {
          line-height: 14px;
          font-size: 14px;
          color: #2b2b2b;
          display: inline-block;
        }

        .phone {
          font-size: 14px;
          line-height: 1;
          font-weight: 400;
          color: #2b2b2b;
          margin-left: 14px;
          display: inline-block;
        }
      }

      .address {
        line-height: 16px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #7a7a7a;
        margin-top: 9px;
      }
    }
  }
}


.orderCard {
  background: #FFFFFF;
  border-radius: 8px;
  margin: 10px;

  .state {
    height: 34px;
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 34px;
    padding: 0 19px;
    border-bottom: 1px solid #eee;
  }

  .goodslist {

    .goodsItem {
      padding: 16px 18px 21px;
      .btnList {
        display: flex;
        justify-content: flex-end;
      }
      .plain {
        width: 68px;
        height: 23px;
        border: 1px solid #999999;
        border-radius: 112px;

        font-size: 11px;
        color: #212121;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 6px;
        &.active {
          color: #ff0000;
          border-color: #ff0000;
        }
        &+.plain {
          margin-left: 10px;
        }
      }
      &+.goodsItem {
        padding-top: 0;
      }
      .goodsInfo {
        display: flex;
        align-items: flex-start;
      }
      .van-image {
        width: 110px;
        min-width: 110px;
        height: 92px;
        margin-right: 13px;
      }

      .right {
        flex: 1;
        width: 0;
        .top {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .name {
            width: 180px;
            font-size: 12px;
            color: #333333;
            line-height: 15px;

          }

          .price {
            line-height: 15px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-weight: bold;
            color: #D53A26;
            span:last-child {
              font-size: 15px;
            }
          }
        }

        .sku {
          background: #eeeeee;
          border-radius: 2px;

          padding: 5px 10px;
          line-height: 10px;
          font-size: 10px;
          color: #666666;

          display: inline-block;
          margin: 5px 0 4px;
        }

        .tagList {
          margin: 6px 0 0;
          font-size: 0;
          .tag {
            display: inline-block;
            background: #ffffff;
            border: 1px solid #ff0000;
            border-radius: 3px;
            line-height: 10px;
            font-size: 10px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ff0000;

            padding: 2px 5px;
          }
        }

        .tips {
          line-height: 10px;
          font-size: 10px;
          color: #e7ac58;
        }

      }

    }
  }
}
.orderInfoCard {
  background: #FFFFFF;
  border-radius: 8px;
  margin: 10px;
  .top {
    padding: 10px 0;
  }
  .item {
    padding: 6px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .key {
      font-size: 12px;
      color: #333;

      span {
        color: #999;
        font-size: 10px;
      }
    }

    .value {
      font-size: 12px;
      color: #333;
      font-weight: bold;

      &.price {
        color: #d53a26;
      }

      &.gray {
        font-weight: 400;
        color: #999;
      }
    }
  }
}

.placeholder {
  height: 65px;
}
.bottomHandle {
  height: 53px;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 11px;


  .van-button {
    padding: 0;
    width: 68px;
    font-size: 11px;
  }

  .van-button--plain.van-button--info {
    border-color: #999 !important;
  }

  .van-button--mini + .van-button--mini {
    margin-left: 7px;
  }
}

.currentLogistics {
  background: #EEEEEE;
  border-radius: 2px;
  padding: 8px 7px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
  img {
    width: 15px;
    min-width: 15px;
    margin-right: 5px;
    margin-top: 2px;

  }

  .right {
    flex: 1;
    .info {
      font-size: 10px;
      color: #212121;
      line-height: 15px;
    }

    .time {
      margin-top: 3px;
      font-size: 10px;
      color: #999999;
      line-height: 15px;
    }
  }
}

.logistics {
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 10px;
  padding:  0 0 11px;
  margin-top: -4px;
  span {
    display: inline-block;
    width: 26px;
    height: 13px;
    background: #e5e5e5;
    border-radius: 2px;
    line-height: 13px;
    text-align: center;

    font-size: 10px;
    color: #999999;

    margin-left: 3px;
  }
}
</style>
