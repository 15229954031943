<template>
  <!-- 选择售后类型弹窗 -->
  <van-popup
      v-model="show"
      position="bottom"
      :style="{ background: '#f4f4f4', borderRadius: '13px 13px 0 0' }"
  >
    <div class="title">
      <div v-if="type===0">确认退款信息</div>
      <div v-if="type===1">确认退货信息</div>
      <van-icon
          name="close"
          color="#c7c7c7"
          size="20"
          @click="show = false"
      />
    </div>

    <div class="body">
      <div class="orderCard" v-for="(item, index) in goods">
        <div class="goodslist">
          <div class="goodsItem">
            <div class="goodsInfo">
              <van-image
                  :src="item.pic"
              />
              <div class="right">
                <div class="top">
                  <div class="name van-multi-ellipsis--l2">
                    <div>{{ item.subProductName }}</div>
                  </div>
                  <div class="price">
                    <van-icon name="delete-o" size="20" color="#aaa" @click="delGoods(index)"/>
                  </div>
                </div>
                <div class="sku">规格：{{ item.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ item.num }}</div>
                <div class="tips" v-show="item.saleProduct.deliverGoodsTemp === '1'">下单{{
                    item.saleProduct.day
                  }}天内发货：
                </div>
                <div class="tips" v-show="item.saleProduct.deliverGoodsTemp === '2'">
                  预计配送时间：{{ item.saleProduct.deliverGoodsContent }}
                </div>
                <div class="tagList">
                  <div class="tag" v-show="item.saleProduct.deliveryMode ==='2'">配送到校</div>
                  <div class="tag" v-show="item.saleProduct.deliveryMode ==='1'">快递到家</div>
                </div>

              </div>
            </div>
          </div>

          <van-cell-group :border="false">
            <van-cell :border="false" title="商品状态" is-link @click.native="startSelectStatus(item)">
              <template>
                <span style="color: #333;">{{ getStatusText(item.status) }}</span>
              </template>
            </van-cell>
            <van-cell :border="false" title="退款原因" is-link @click.native="startSelectReason(item)">
              <template>
                <span style="color: #666;">{{ item.reason_text }}</span>
                <!--                               <span style="color: #666;">{{getReasonText(item.reason_id)}}</span>-->
              </template>
            </van-cell>
            <van-cell :border="false" title="退款数量" is-link>
              <template>
                <div class="inputValue">
                  <span>¥</span>
                  <van-field v-model="item.afterSalesNum" input-align="right" type="digit"
                             @blur="item.afterSalesNum > item.num ? item.afterSalesNum = item.num : void 0"/>
                </div>
                <div class="inputTips">最多退{{ item.num }} 退完为止</div>
              </template>

              <template #right-icon>
                <img src="@/assets/images/edit.png" class="editIcon"/>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </div>

      <van-cell :border="false" v-if="type===1" title="退回凭证">
        <van-uploader
            v-model="fileList"
            :max-count="4"
            :after-read="cropUpload"
            :before-read="beforeRead"/>
      </van-cell>
      <van-field v-if="type===1" v-model="sendBackOrderNumber" label="退回快递单号" />

      <empty
          v-if="goods.length <= 0"
          text="您还未选择需要退款的商品"
      />
    </div>

    <div class="bottom">
      <div class="total">
        共{{ allCount }}件商品售后
<!--        ，退款总金额由后台审核人员与您协商决定 -->
<!--        <span class="money">￥{{ allMoney }}</span> <span-->
<!--          class="postCost">（含邮费￥{{ goods.length > 0 ? postCost : 0 }}）</span>-->
      </div>
      <van-button
          block
          round
          color="#D53A26"
          type="primary"
          size="small"
          :disabled="allCount <= 0"
          @click="onConfirm"
      >
        提交申请
      </van-button>
    </div>

    <van-action-sheet v-model="showReason" title="退款原因" close-icon="close">
      <van-radio-group v-model="reason_stack">
        <van-cell-group>
          <van-cell v-for="item in reasonList" :title="item.text" clickable @click="reason_stack = item.value">
            <template #right-icon>
              <van-radio :name="item.value" checked-color="#FF553E"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>

      <div style="padding: 10px 18px 18px 23px;">
        <van-button color="#D53A26" type="primary" size="small" block round @click="selectReason">提交</van-button>
      </div>
    </van-action-sheet>

    <van-action-sheet v-model="showStatus" title="商品状态" close-icon="close">
      <van-radio-group v-model="reason_stack">
        <van-cell-group>
          <van-cell v-for="item in statusList" :title="item.text" clickable @click="reason_stack = item.value">
            <template #right-icon>
              <van-radio :name="item.value" checked-color="#FF553E"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>

      <div style="padding: 10px 18px 18px 23px;">
        <van-button color="#D53A26" type="primary" size="small" block round @click="selectStatus">提交</van-button>
      </div>
    </van-action-sheet>
  </van-popup>
</template>

<script>
export default {
  name: "RefundDialog",
  props: {},
  components:{
  },
  data() {
    return {
      show: false,
      postCost: 8,
      goods: [],
      //售后类型
      type:"",
      //寄回凭证
      voucher:[],
      //展示组件
      fileList:[],
      //快递单号
      sendBackOrderNumber:"",
      reason_goodsId: '',
      reason_stack: '',
      reasonId: '',

      showReason: false,
      showStatus: false,
      uploadLoading:false,
      reasonList: [{
        text: '不喜欢/不想要',
        value: 1
      }, {
        text: '未按约定时间发货',
        value: 2
      }, {
        text: '尺码拍错',
        value: 3
      }, {
        text: '缺货',
        value: 4
      }, {
        text: '拍多了',
        value: 5
      }, {
        text: '其他',
        value: 6
      }],
      statusList: [{
        text: '已收到货',
        value: 1
      }, {
        text: '未收到货',
        value: 2
      }]
    };
  },
  computed: {
    allCount() {
      if (this.goods.length === 0) {
        return 0
      }
      let allCount = 0;
      this.goods.forEach(e => {
        allCount += Number(e.afterSalesNum || 0)
      })
      return allCount
    },
    allMoney() {
      if (this.goods.length === 0) {
        return 0
      }
      let allMoney = 0;
      this.goods.forEach(e => {
        allMoney += Number(e.price || 0)
      })
      return allMoney
    }
  },
  methods: {
    showPop(goodsList,type) {
      this.type = type;
      this.goods = goodsList.map(e => {
        e.afterSalesNum = e.selectNum;
        e.status = 1;
        return e
      }); //加上是否收货标识符
      this.show = true;
    },

    delGoods(index) {
      this.$dialog.confirm({
        title: '提示',
        message: `是否取消该商品退款`,
      }).then(() => {
        // TODO SEVER

        this.goods.splice(index, 1);
      })
          .catch(() => {
            // on cancel
          });

    },
    onConfirm() {
      this.$emit('onNextStep', this.goods,this.type,this.voucher,this.sendBackOrderNumber)
      this.show = false
    },
    startSelectReason(item) {
      this.reason_goodsId = item.goodsId;
      this.reason_stack = item.reason_id;
      this.reason_id = item.reason_id;
      this.showReason = true;
    },
    startSelectStatus(item) {
      //反正一次只会打开一个弹窗， 可以跟原因公用一些字段
      this.reason_goodsId = item.goodsId;
      this.reason_stack = item.status;
      this.reason_id = item.status;
      this.showStatus = true;
    },
    selectReason() {
      for (let i = 0; i < this.goods.length; i++) {
        const e = this.goods[i];
        if (e.goodsId === this.reason_goodsId) {
          e.reason_id = this.reason_stack
          this.showReason = false
          return;
        }
      }
    },

    selectStatus() {
      for (let i = 0; i < this.goods.length; i++) {
        const e = this.goods[i];
        if (e.goodsId === this.reason_goodsId) {
          e.status = this.reason_stack
          this.showStatus = false
          return;
        }
      }
    },
    getReasonText(val) {
      if (val === '') {
        return {text: ''}
      }
      return this.reasonList.find(e => e.value == val).text;
    },
    getStatusText(val) {
      if (val === '') {
        return {text: ''}
      }
      return this.statusList.find(e => e.value == val).text;
    },

    postCodeValueTest() {
      if (this.postCost > 8) {
        this.postCost = 8
      }
      if (this.postCost < 0 || !this.postCost) {
        this.postCost = 0
      }
    },
    beforeRead(file) {
      const isImgType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isImgType) {
        this.$toast("上传图片只能是 JPG或PNG 格式!");
        return false;
      }
      if (!isLt10M) {
        this.$toast("上传图片大小不能超过 10MB!");
        return false;
      }
      return true;
    },
    // 裁剪点击确定
    cropUpload(file) {
      this.uploadLoading = true;
      file.status = "uploading";
      file.message = "上传中...";
      let form = new FormData();

      form.append('file', file.file);

      this.$http.post(this.baseMallUrl + `/h5_mall/upload`, form,
          {headers:{"content-type":  "multipart/form-data"}}).then((res) => {
        if (res.data) {
          file.status = "done";
          file.message = "上传完成";
          this.uploadLoading = false;
          this.voucher.push(res.data.url);
        } else {
          file.status = "failed";
          file.message = "上传失败";
          this.uploadLoading = false;
          this.$toast(res.data);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #212121;

  .van-icon {
    position: absolute;
    top: 17px;
    right: 19px;
  }
}

.body {
  max-height: 70vh;
  overflow: auto;
}

.bottom {
  padding: 16px 18px;
  margin-top: 15px;
  background-color: #fff;

  .total {
    line-height: 1;
    padding-bottom: 13px;
    font-size: 14px;
    color: #333;
    font-weight: 500;

    span {
      font-weight: 400;
    }

    .money {
      color: #D53A26
    }

    .postCost {
      color: #999;
    }
  }
}


.orderCard {
  background: #FFFFFF;
  border-radius: 8px;
  margin: 0 10px 10px;
  padding-bottom: 10px;

  .goodslist {

    .goodsItem {
      padding: 16px 18px 21px;

      & + .goodsItem {
        padding-top: 0;
      }

      .goodsInfo {
        display: flex;
        align-items: flex-start;
      }

      .van-image {
        width: 110px;
        min-width: 110px;
        height: 92px;
        margin-right: 13px;
      }

      .right {
        flex: 1;
        width: 0;

        .top {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .name {
            width: 155px;
            font-size: 12px;
            color: #333333;
            line-height: 15px;

          }

          .price {
            line-height: 15px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-weight: bold;
            color: #D53A26;

            span:last-child {
              font-size: 15px;
            }
          }
        }

        .sku {
          background: #eeeeee;
          border-radius: 2px;

          padding: 5px 10px;
          line-height: 10px;
          font-size: 10px;
          color: #666666;

          display: inline-block;
          margin: 5px 0 4px;
        }

        .tagList {
          margin: 6px 0 0;
          font-size: 0;

          .tag {
            display: inline-block;
            background: #ffffff;
            border: 1px solid #ff0000;
            border-radius: 3px;
            line-height: 10px;
            font-size: 10px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ff0000;

            padding: 2px 5px;
          }
        }

        .tips {
          line-height: 10px;
          font-size: 10px;
          color: #e7ac58;
        }

        .plain {
          width: 68px;
          height: 23px;
          border: 1px solid #999999;
          border-radius: 112px;

          font-size: 11px;
          color: #212121;
          display: flex;
          align-items: center;
          justify-content: center;
          float: right;
          margin-top: 6px;

          &.active {
            color: #ff0000;
            border-color: #ff0000;
          }

          & + .plain {
            margin-right: 10px;
          }
        }
      }

    }
  }
}

::v-deep .van-cell {
  padding: 6px 16px;
}

::v-deep .van-field__control {
  color: #333;
  font-size: 14px;
}

.editIcon {
  height: 13px;
  width: 13px;
  margin-top: 2px;
}

.inputValue {
  color: #333;
  margin-top: -3px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    font-size: 11px;
    margin-right: 1px;
  }

  .van-cell {
    padding: 0;
    width: 30px;
  }
}

.inputTips {
  font-size: 12px;
  color: #999;
  margin-right: 4px;
}

</style>
