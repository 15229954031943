<template>
  <van-popup
      v-model="show"
      position="bottom"
      :style="{ background: '#f4f4f4', borderRadius: '13px 13px 0 0' }"
  >
    <div class="title">
      <div>选择商品</div>
      <van-icon name="close" color="#c7c7c7" size="20" @click="show = false"/>
    </div>

    <div class="body">
      <van-checkbox-group v-model="selectId">
        <div class="checboxBody" v-for="item in goods">
          <div class="goodsItem">
            <div class="goodsInfo">
              <div class="imgBox">
                <van-image
                    :src="item.pic"
                />
<!--                <div class="state">配送中</div>-->
              </div>
              <div class="right">
                <div class="name van-multi-ellipsis--l2">
                  <span class="ys" v-show="item.libckflag ===0">预售</span>
                  <span class="xh" v-show="item.libckflag ===1">现货</span>
                  <div style="display: contents;">{{ item.subProductName }}</div>
                </div>
                <div class="top">
                  <div class="price">
                    <span>¥</span>
                    <span>{{ item.price }}</span>
                  </div>
                  <van-stepper v-model="item.selectNum" :max="item.num" button-size="20px"/>
                </div>
<!--                <div class="sku">-->
<!--                  规格：{{ item.skuName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数量：{{ item.num }}-->
<!--                </div>-->
                <div class="tagList" style="display:inline-block;">
                  <div class="tag"  v-show="item.productType ==0">配送到校</div>
                  <div class="tag" v-show="item.productType ==1">快递到家</div>
                </div>
                <div style="margin-left: 10px;display:inline-block;">
                  <div v-if="item.deliverGoodsTemp === '1'"
                       class="tips">下单{{item.day}}天内发货</div>
                  <div v-if="item.deliverGoodsTemp === '2'"
                       class="tips">预计配送时间：{{ item.deliverGoodsDate }}</div>
                  <div v-if="item.deliverGoodsTemp === '3'"
                       class="tips"
                       v-html="item.deliverGoodsContent">
                  </div>
                </div>

              </div>
            </div>

          </div>
          <van-checkbox checked-color="#FF553E" shape="round" :name="item.subProductId" v-if="goods.length > 1"/>

        </div>
      </van-checkbox-group>
    </div>

    <div class="bottom">
      <van-button
          block
          round
          color="#D53A26"
          type="primary"
          size="small"
          @click="onConfirm"
          :disabled="selectId.length === 0"
      >
        下一步
      </van-button>
    </div>

  </van-popup>
</template>

<script>
export default {
  name: "selectProductDialog",
  data() {
    return {
      goods: [],
      show: false,
      selectId: [],
      functionType:"",
    };
  },
  methods: {
    /**
     * @param { Array } goodList 理论上这个item应该是订单的商品合集, 比如[{goodsId: 1, goodsName: '夏季套装}, { goodsId: 2, goodsName: '夏季套装'}]
     * @param { String | Number } currentId 当前选中的 goodsId
     * @param { Number } dialogType 1 退款，只能选择“仅退款”、“退货退款” 2 售后 可以选择“仅退款”、“退货退款”、”换货“
     */
    showPop(goods, currentId,functionType) {
      goods.forEach(item=>{
        item.selectNum = item.num;
      })
      this.goods = goods
      this.selectId = [currentId]; //默认选中你在订单列表中选择的那个
      this.functionType = functionType;
      this.show = true
    },

    onConfirm() {
      let result = []
      this.goods.forEach(e => {
        if (this.selectId.indexOf(e.subProductId) !== -1) {
          result.push(e);
        }
      }) // 获取选中的商品信息
      this.$emit('onNextStep', result,this.functionType)
      this.show = false
    }
  }
}
</script>

<style lang="less" scoped>
.body {
  background-color: #f4f4f4;
}

.ys {
  width: 32px;
  background: linear-gradient(264deg, #f58130, #ef2514);
  border-radius: 2px;

  font-size: 12px;
  font-weight: 500;
  color: #F4F4F4;
  line-height: 12px;
  text-align: center;
  padding: 2px 0;
  display: inline-block;
  margin-right: 3px;

}

.xh {
  width: 32px;
  background: linear-gradient(264deg, #4db3ee, #00A0E9);
  border-radius: 2px;

  font-size: 12px;
  font-weight: 500;
  color: #F4F4F4;
  line-height: 12px;
  text-align: center;
  padding: 2px 0;
  display: inline-block;
  margin-right: 3px;

}

.title {
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #212121;

  .van-icon {
    position: absolute;
    top: 17px;
    right: 19px;
  }
}

.body {
  background-color: #fff;
  border-radius: 13px 13px 0 0;
  padding: 0 31px;

  .choose {
    .name {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #212121;
      line-height: 15px;
      padding: 18px 0 11px 0;
    }

    .chooseList {
      font-size: 0;
      padding-bottom: 21px;
      border-bottom: 1px solid #f4f4f4;

      .item {
        display: inline-block;
        width: 68px;
        height: 23px;
        border: 1px solid #999;
        border-radius: 4px;

        font-size: 11px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #212121;
        line-height: 21px;
        text-align: center;
        margin-right: 6px;
        transition: all;

        &.active {
          color: #d53a26;
          border: 1px solid #d53a26;
        }
      }
    }
  }

  .checboxBody {
    display: flex;
    align-items: center;
  }

  .goodsItem {
    padding-bottom: 25px;
    flex: 1;

    .name {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 15px;
      padding: 13px 0;
    }

    .goodsInfo {
      display: flex;
      align-items: flex-start;
    }

    .van-image,
    .imgBox {
      width: 110px;
      min-width: 110px;
      height: 92px;
    }

    .imgBox {
      margin-right: 20px;
      position: relative;

      .state {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 0px 0px 5px 5px;
        text-align: center;
        line-height: 20px;
        color: #fff;
        font-size: 11px;
      }
    }

    .right {
      flex: 1;
      width: 0;
      font-size: 0;

      .top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .price {
          line-height: 15px;
          font-size: 12px;
          font-weight: bolder;
          color: #d53a26;

          span:last-child {
            margin-left: 2px;
            font-size: 15px;
          }
        }
      }

      .sku {
        background: #eeeeee;
        border-radius: 2px;
        padding: 5px 10px;
        line-height: 10px;
        font-size: 10px;
        color: #666666;
        display: inline-block;
        margin-top: 8px;
      }

      .tagList {
        font-size: 0;
        margin-top: 8px;

        .tag {
          display: inline-block;
          background: #ffffff;
          border: 1px solid #ff0000;
          border-radius: 3px;
          line-height: 10px;
          font-size: 10px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ff0000;

          padding: 2px 5px;
        }
      }

      .tips {
        line-height: 10px;
        font-size: 10px;
        color: #e7ac58;
        margin-top: 8px;
      }
    }
  }
}

.bottom {
  padding: 18px;
  margin-top: 29px;
  background-color: #fff;
}

::v-deep .van-checkbox__icon .van-icon {
  border-radius: 50%;
}
</style>
