<template>
  <div>
    <van-action-sheet v-model="show">
      <img class="skuClose" @click="show = false" src="@/assets/images/goods/goodsDetail/skuClose.png" alt="">
      <div class="content">
        <div class="goodsInfo">
          <img :src="product.image" alt="">
          <div class="info">
<!--            <div class="price">-->
<!--              <span>¥</span>-->
<!--              <span>{{ product.price }}</span>-->
<!--            </div>-->
            <div class="name">
              {{ product.name }}
            </div>
          </div>
        </div>

        <!-- 选择规格方式 -->

        <div class="skuBody">
          <!-- 我把保存按钮放在组件里面， 是因为不知道后端会怎么定义数据格式，
          放在组件里面的话，点击购买之后数据处理直接在子组件处理、组装， 完事了上发到SkuIndex组件，
          skuIndex则不会对数据进行处理，直接上发父组件 / 父页面 （如果有需要的话， 本场景不需要） -->

          <div style="height: 1px; width: 100%; background-color: #f5f5f5;"></div>
          <!-- 按套选择规格 -->
          <replace-product-sku ref="skuCustom"
                               :product="product"
                               :sale-product-id.sync="saleProductId"
                               :libckflag.sync = "libckflag"
                               :change-num.sync= "changeNum"
                               :key="timer"
                               @onSubmit="onSubmit"/>
        </div>

      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import SKUTYPE from '@/views/goods/components/skuType'
import {mapActions} from "vuex";
import ReplaceProductSku from "./replaceProductSku";
export default {
  components: {ReplaceProductSku},
  name: "replaceProduct",
  watch: {
    subProductId: {
      handler(val) {
        if (val) {
          this.queryProduct(val);
        }
      },
      immediate: true,
    }
  },
  props:{
    // saleProductId:{
    //   type: String,
    //   "default":""
    // },
    // changeNum:{
    //   type:Number,
    //   "default":0
    // }
  },
  data() {
    return {
      SKUTYPE,
      show: false,
      subProductId:"",
      //时间戳
      timer:"",
      changeNum:0,
      saleProductId:"",
      libckflag:"",
      type: SKUTYPE.INIT,
      callback: null,
      product: {},
    }
  },
  created() {
    this.timer = new Date().getTime();
  },
  methods: {
    ...mapActions(['queryShoppingCartNum']),
    queryProduct(id) {
      this.$http.get(`/product/h5_product/queryProductInfo/${id}`).then(res => {
        let product = res.data.product;
        product.imgs = product.image.split(',');
        this.product = product;
      });
    },
    showPop(callback = null, type = SKUTYPE.INIT) {
      this.type = type; // SKUTYPE
      if (callback) {
        this.callback = callback
      } else {
        this.callback = null
      }
      this.show = true
    },

    onTypeSelect(type) {
      this.type = type
    },

    onSubmit(data) {
      if (this.callback) {
        this.callback(data)
        this.show = false
        return;
      }

      this.show = false
      // TODO SERVER
      // 否则就发起请求
    }
  }
}
</script>

<style lang="less" scoped>
.skuClose {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 26px;
  right: 19px;
  z-index: 11;
}

.content {
  padding: 0 18px;
  transition: all .2s;
  position: relative;

  .goodsInfo {

    display: flex;
    align-items: flex-end;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 24px 0 10px 0;
    z-index: 10;

    img {
      width: 100px;
      height: 84px;
      border-radius: 5px;
      margin-right: 15px;
    }

    .price {
      height: 15px;

      span {
        font-weight: bold;
        color: #D53A26;

        &:first-child {
          font-size: 12px;
          margin-right: 3px;
        }

        &:last-child {
          font-size: 18px;
        }
      }
    }

    .name {
      margin-top: 12px;
      font-size: 10px;
      font-weight: 400;
      color: #333333;
      line-height: 15px;
    }
  }


}

.start {
  height: 25px;
  background: #F3E9FE;
  border-radius: 4px;
  line-height: 25px;
  padding: 0 10px;

  font-size: 10px;
  font-weight: 400;
  color: #C226D5;
  line-height: 25px;

}


.rule {
  width: 100%;
  margin-bottom: 10px;
}
</style>
