<template>
  <!-- 选择售后类型弹窗 -->
  <van-popup
      v-model="show"
      position="bottom"
      :style="{ background: '#f4f4f4', borderRadius: '13px 13px 0 0' }"
  >
    <div class="title">
      <div>选择退款原因</div>
      <van-icon
          name="close"
          color="#c7c7c7"
          size="20"
          @click="show = false"
      />
    </div>

    <div class="body">
      <div class="goodsList">
        <van-image
            v-for="item in goods"
            :class="{active: item.subProductId === currentId}"
            @click.native="currentId = item.subProductId"
            :src="item.pic"
        />
      </div>

      <div v-for="item in goods" :key="item.subProductId">
        <van-radio-group v-if="currentId === item.subProductId" v-model="item.reason_stack">
          <van-cell-group>
            <van-cell v-for="reason in reasonList" :key="item.subProductId + '_' + reason.value" :title="reason.text"
                      clickable @click="select(item, reason)">
              <template #right-icon>
                <van-radio :name="reason.text" checked-color="#FF553E"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
      <van-field v-if="textShow" v-model="thisText" placeholder="请输入备注"/>
    </div>

    <div class="bottom">
      <van-button
          block
          round
          color="#D53A26"
          type="primary"
          size="small"
          :disabled="disabled"
          @click="onConfirm"
      >
        确定
      </van-button>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: "RefundDialog",
  props: {},
  data() {
    return {
      show: false,
      textShow: false,
      thisText: "",
      thisIndex: 0,
      goods: [],
      currentId: '',
      disabled: true,
      //售后类型
      type:"",
      reasonList: [{
        text: '不喜欢/不想要',
        value: 1
      }, {
        text: '未按约定时间发货',
        value: 2
      }, {
        text: '尺码拍错',
        value: 3
      }, {
        text: '缺货',
        value: 4
      }, {
        text: '拍多了',
        value: 5
      }, {
        text: '其他',
        value: 6
      }]
    };
  },
  watch: {
    thisText:{
      handler(val) {
        this.goods.forEach(item=>{
          item.reason_text = val
        })
        this.disabled = !this.goods.every(e => e.reason_text)
        this.$forceUpdate()
      },
      immediate: true,
    }
  },
  methods: {
    showPop(goodsList,type) {
      Object.assign(this.$data, this.$options.data());
      this.type = type;
      goodsList.forEach(e => {
        e.reason_id = '';
        e.reason_text = '';
        e.reason_stack = '';
      })
      this.goods = goodsList;
      this.currentId = this.goods[0].subProductId
      this.disabled = true
      this.show = true;
    },

    select(item, reason) {
      item.reason_id = reason.value;  // 退款原因的字典编码， 将在确认退款信息弹窗展示
      // item.reason_text = reason.text; // 退款原因的中文， 将在确认退款信息弹窗展示
      this.textShow = reason.value === 6;
      item.reason_text = this.textShow ? this.thisText : reason.text;
      this.disabled = !this.goods.every(e => e.reason_text)
      this.$forceUpdate()
    },

    onConfirm() {
      this.$emit('onNextStep', this.goods,this.type)
      this.show = false
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #212121;

  .van-icon {
    position: absolute;
    top: 17px;
    right: 19px;
  }
}

.body {
  background-color: #fff;
  border-radius: 13px 13px 0 0;
  padding: 0 31px;

  .goodsList {
    padding: 13px 0;
    margin-bottom: 0px;

    .van-image {
      width: 57px;
      height: 48px;
      border-radius: 5px;
      margin-right: 10px;

      &.active {
        border-radius: 5px;
        border: 1px solid #D53A26;
      }
    }


  }
}

.bottom {
  padding: 18px;
  margin-top: 29px;
  background-color: #fff;
}

::v-deep .van-cell-group {
  &::after {
    display: none;
  }
}

::v-deep .van-cell {
  padding: 10px 0;

  &::after {
    left: 0;
    right: 0;
  }
}
</style>
